<template>
  <Stack tag="section" justify="center" class="w-full" gap="3xs" :breakpoints="{ justify: { lg: 'start' } }">
    <br-manage-menu-button class="edit-social-menu-button-cms" :menu="menu" v-if="page.isPreview()" />

    <NuxtLink
      :to="menuLink.getLink().href"
      v-for="(menuLink, index) in menu.items ?? []"
      :data-test-id="`secondaryFooterLinkFirstLine-${index}`"
      :key="`${menuLink.getName()}-${index}`"
      :aria-label="`${menuLink.getName()} page`"
    >
      <Text is="span" size="caption">
        {{ menuLink.getName() }}
        <span v-if="index !== menu.items.length - 1">|</span>
      </Text>
    </NuxtLink>
  </Stack>
</template>

<script lang="ts" setup>
import type { Component, Page, Content } from "@bloomreach/spa-sdk";
const props = defineProps<{ component: Component; page: Page }>();
const { page } = toRefs(props);

interface ContentWithItems extends Content {
  items: any;
}

const { menu: menuRef } = props.component.getModels();
const menu = props.page.getContent(menuRef) as ContentWithItems;
</script>
